var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact-card" }, [
      _c(
        "div",
        { staticClass: "contact-card__main", on: { click: _vm.data.click } },
        [
          _c("div", { staticClass: "contact-card__main_main" }, [
            _c("div", { staticClass: "contact-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Phone")) },
              }),
              _c("div", { domProps: { textContent: _vm._s(_vm.item.phone) } }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }